import React, { Component } from 'react';
import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';
import get from 'lodash/get';
import { Button, Img, TextField } from 'components/base';
import Language from 'constants/Language';
import { WidgetNewsletter } from 'sharedTypes';
import { Status } from 'types';
import { onWindowResize } from 'utils/onWindowResize';
import { subscribe } from '../NewsletterForm/subscribe';

interface Props {
  className?: string;
  widget: WidgetNewsletter;
  isFullWidth?: boolean;
  isSectionPage?: boolean;
  isNarrow?: boolean;
}

interface State {
  emailAddress: string;
  showInfoMessage: boolean;
  showSuccessMessage: boolean;
  placeholder: string;
  emailInputIsFocused: boolean;
  status: Status;
  showErrorState: boolean;
  responseMessage: string | null;
}

// NEXT_TODO:
// Manually QA this, it hasn't been tested yet!
//
class NewsletterWidget extends Component<Props, State> {
  emailId: string;

  constructor(props: Props) {
    super(props);

    this.emailId = `email-${new Date().getTime()}`;
    this.submit = this.submit.bind(this);
  }

  removeResizeListener = () => {};

  state: State = {
    placeholder: Language.t('Widgets.Newsletter.emailFieldPlaceholder'),
    emailInputIsFocused: false,
    showErrorState: false,
    emailAddress: '',
    showInfoMessage: false,
    showSuccessMessage: false,
    status: Status.IDLE,
    responseMessage: null,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { status } = this.state;
    if (prevState.status === Status.PENDING && status === Status.REJECTED) {
      this.setState({ showInfoMessage: true });
      setTimeout(() => this.setState({ showInfoMessage: false }), 4000);
    }

    if (prevState.status === Status.PENDING && status === Status.FULFILLED) {
      this.setState({ showSuccessMessage: true });
      setTimeout(
        () => this.setState({ showSuccessMessage: false, emailAddress: '' }),
        5000
      );
    }
  }

  async submit() {
    this.setState({ status: Status.PENDING });
    const result = await subscribe(this.state.emailAddress);
    this.setState({
      status: result.status,
    });
  }

  componentDidMount() {
    if (this.props.isNarrow) {
      this.removeResizeListener = onWindowResize(this.getPlaceholder);
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  getPlaceholder = () => {
    const currentViewportWidth: number = Math.round(window.innerWidth);

    if (currentViewportWidth < 1510 && currentViewportWidth > 1080) {
      this.setState({
        placeholder: Language.t(
          'Widgets.Newsletter.emailFieldPlaceholderShort'
        ),
      });
    } else {
      this.setState({
        placeholder: Language.t('Widgets.Newsletter.emailFieldPlaceholder'),
      });
    }
  };

  handleTryAgainClick = () => {
    this.setState({ showErrorState: false });
  };

  render() {
    const { className, widget, isFullWidth, isSectionPage, isNarrow } =
      this.props;
    const {
      showInfoMessage,
      emailAddress,
      showSuccessMessage,
      placeholder,
      showErrorState,
    } = this.state;

    const variant = get(widget, 'variant', '');
    const text = get(widget, 'text', '');
    const variantWidthIsFullWidth = !!isFullWidth;
    const variantColorIsRed = variant === 'red';

    if (typeof text === 'string') {
      return null;
    }

    return (
      <>
        <div
          className={cx({
            'NewsletterWidget--style-full-width inner-content-max-width mxauto':
              variantWidthIsFullWidth,
          })}
        >
          <div
            className={cx(
              'NewsletterWidget flex relative transition-short',
              className,
              {
                'flex-col': !variantWidthIsFullWidth,
                'widget-container-red': variantColorIsRed,
                'widget-container-black': !variantColorIsRed,
                'flex-col lg:px1_25 lg:py1 lg:flex-row lg:items-center lg:justify-between':
                  variantWidthIsFullWidth,
                'border-thick-black bg-color-black': !variantColorIsRed,
                'color-off-white': !variantColorIsRed && !isSectionPage,
                'color-beige': !variantColorIsRed && isSectionPage,
                'border-thick-red color-red': variantColorIsRed,
              }
            )}
          >
            <p
              className={cx(
                'w100 mb1_5 uppercase itc-cushing text-section-title-xs font-800',
                {
                  'md:none': variantWidthIsFullWidth,
                  'opacity-0 events-none': showSuccessMessage || showErrorState,
                }
              )}
            >
              {Language.t('Widgets.Newsletter.newsletter')}
            </p>
            {text && (
              <div
                className={cx(
                  'NewsletterWidget__text graebenbach text-section-body-xs font-400 transition-short',
                  {
                    'opacity-0 events-none':
                      showSuccessMessage || showErrorState,
                    'color-beige': !variantColorIsRed && isSectionPage,
                  }
                )}
              >
                {showInfoMessage ? (
                  <div
                    className={cx(
                      'NewsletterWidget__info-message opacity-0 transition-short',
                      {
                        'NewsletterWidget__info-message--active':
                          showInfoMessage,
                      }
                    )}
                  >
                    <span className="font-700">
                      {Language.t('Widgets.Newsletter.somethingWentWrong')}
                    </span>
                    {Language.t('Widgets.Newsletter.errorMsg')}
                  </div>
                ) : (
                  <BlockContent blocks={text} />
                )}
              </div>
            )}
            <div
              className={cx(
                'NewsletterWidget__text-field-container transition-short px_75 py_25 radius-xs flex items-center',
                {
                  'NewsletterWidget__text-field-container--black':
                    !variantColorIsRed,
                  'NewsletterWidget__text-field-container--black--focus-state':
                    !variantColorIsRed && this.state.emailInputIsFocused,
                  'NewsletterWidget__text-field-container--red':
                    variantColorIsRed,
                  'bg-color-white-opacity-10': !variantColorIsRed,
                  'mt1_5 lg:mt0 lg:col-5 lg:ml_5': variantWidthIsFullWidth,
                  mt1_5: !variantWidthIsFullWidth,
                  'opacity-0 events-none': showSuccessMessage || showErrorState,
                }
              )}
            >
              <TextField
                className="w100 transition-shorter"
                placeholder={
                  isNarrow
                    ? placeholder
                    : Language.t('Widgets.Newsletter.emailFieldPlaceholder')
                }
                ariaLabel={Language.t('Widgets.Newsletter.emailFieldAriaLabel')}
                id={this.emailId}
                name={Language.t('Widgets.Newsletter.email')}
                variant={variantColorIsRed ? 'red' : 'black'}
                type="email"
                onChange={(value) =>
                  this.setState({ emailAddress: value as string })
                }
                onFocus={() => this.setState({ emailInputIsFocused: true })}
                onBlur={() => this.setState({ emailInputIsFocused: false })}
                value={emailAddress}
              />
              <Button
                ariaLabel={Language.t(
                  'Widgets.Newsletter.submitEmailButtonAriaLabel'
                )}
                className="move-image-right-on-hover p0 bg-color-transparent"
                onClick={this.submit}
              >
                <div
                  className={cx(
                    'button-move-arrow graebenbach text-article-details-xs',
                    {
                      'color-red': variantColorIsRed,
                      'color-gray':
                        !variantColorIsRed && !this.state.emailInputIsFocused,
                      'color-black': this.state.emailInputIsFocused,
                    }
                  )}
                >
                  &rarr;&#xFE0E;
                </div>
              </Button>
            </div>
            <div
              className={cx(
                'absolute transition-short t0 r0 l0 b0 w100 h100 px1 mxauto text-center flex flex-col justify-center items-center',
                {
                  'opacity-0 events-none': !showSuccessMessage,
                }
              )}
            >
              <div
                className={cx('flex flex-col justify-center items-center', {
                  'NewsletterWidget__success-container--normal':
                    !variantWidthIsFullWidth,
                  'NewsletterWidget__success-container--full-width lg:flex-row':
                    variantWidthIsFullWidth,
                  'color-white': !variantColorIsRed && !isSectionPage,
                  'color-beige': !variantColorIsRed && isSectionPage,
                  'color-red': variantColorIsRed,
                })}
              >
                <Img
                  className={cx('NewsletterWidget__check-icon mx_5 mb1_25', {
                    'md:mb0': variantWidthIsFullWidth,
                  })}
                  alt={Language.t('Widgets.Newsletter.checkIconAlt')}
                  src={`/assets/images/check-icon-${
                    variantColorIsRed ? 'red' : 'green'
                  }.svg`}
                />
                <span className="graebenbach text-section-body-xs font-400">
                  <span className="font-700">
                    {Language.t('Widgets.Newsletter.success')}
                  </span>
                  {Language.t('Widgets.Newsletter.successMsg')}
                </span>
              </div>
            </div>

            <div
              className={cx(
                'absolute transition-short t0 r0 l0 b0 w100 h100 px1 mxauto text-center flex flex-col justify-center items-center',
                {
                  'opacity-0 events-none': !showErrorState,
                }
              )}
            >
              <div
                className={cx('flex flex-col justify-center items-center', {
                  'NewsletterWidget__success-container--normal':
                    !variantWidthIsFullWidth,
                  'NewsletterWidget__success-container--full-width lg:flex-row':
                    variantWidthIsFullWidth,
                  'color-white': !variantColorIsRed && !isSectionPage,
                  'color-beige': !variantColorIsRed && isSectionPage,
                  'color-red': variantColorIsRed,
                })}
              >
                <Img
                  className={cx('NewsletterWidget__check-icon mx_5 mb1_25', {
                    'md:mb0': variantWidthIsFullWidth,
                  })}
                  alt={Language.t('Widgets.Newsletter.checkIconAlt')}
                  src={`/assets/images/fail-icon-red.svg`}
                />
                <span className="graebenbach text-section-body-xs font-400">
                  <span className="font-700">
                    {Language.t('Widgets.Newsletter.somethingWentWrong')}
                  </span>
                  {Language.t('Widgets.Newsletter.errorMsg')}
                </span>
                <Button
                  ariaLabel={Language.t('Widgets.Newsletter.tryAgain')}
                  onClick={this.handleTryAgainClick}
                  className="bg-color-transparent"
                >
                  <span
                    className={cx('graebenbach text-section-body-xs font-400', {
                      'NewsletterWidget__try-again--white color-white':
                        !variantColorIsRed && !isSectionPage,
                      'NewsletterWidget__try-again--beige color-beige':
                        !variantColorIsRed && isSectionPage,
                      'NewsletterWidget__try-again--red color-red':
                        variantColorIsRed,
                    })}
                  >
                    {Language.t('Widgets.Newsletter.tryAgain')}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewsletterWidget;
