import jsonp from 'jsonp';

import { Status } from 'types';

import Language from 'constants/Language';

export interface SubscriptionResponse {
  status: Status;
  responseMsg: string;
}

const sanitizeSubscriptionURL = (subscribeURL: string, emailAddress: string) =>
  subscribeURL.replace(
    'list-manage.com/subscribe?',
    'list-manage.com/subscribe/post-json?'
  ) + `&EMAIL=${emailAddress}`;

export const subscribe = (
  emailAddress: string,
  subscribeURL = process.env.NEXT_PUBLIC_MAILCHIMP_SUBSCRIBE_URL as string
): Promise<SubscriptionResponse> => {
  return new Promise((resolve, rej) => {
    const url = sanitizeSubscriptionURL(subscribeURL, emailAddress);

    jsonp(
      url,
      {
        param: 'c',
      },
      (err, data) => {
        if (err) {
          resolve({
            status: Status.REJECTED,
            responseMsg: err.toString(),
          });
        } else if (data.result !== 'success') {
          if (data.msg.includes('subscribed')) {
            resolve({
              status: Status.FULFILLED,
              responseMsg: Language.t('Global.emailSubscribeSuccessMessage'),
            });
          } else {
            resolve({
              status: Status.REJECTED,
              responseMsg: data.msg.slice(4),
            });
          }
        } else {
          // On subscription success, inject the geq.suppress() script
          const script = document.createElement('script');
          script.text = 'geq.suppress();';
          document.body.appendChild(script);

          resolve({
            status: Status.FULFILLED,
            responseMsg: Language.t('Global.emailSubscribeSuccessMessage'),
          });
        }
      }
    );
  });
};
