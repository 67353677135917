import React, { ForwardedRef, forwardRef } from 'react';
import { Column } from 'sharedTypes';
import { Img } from 'components/base';
import { get } from 'lodash';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

interface Props {
  column: Column;
}

export const ColumnsAuthor = forwardRef(function ColumnsAuthor(
  { ...props }: Props,
  forwardedRef: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const image = get(props.column, 'image');

  return (
    <div ref={forwardedRef} className={'ColumnsAuthor'}>
      <div className={'ColumnsAuthor__container'}>
        {image && (
          <Img
            alt={image?.alt ?? ''}
            className="ColumnsAuthor__container__image w100 grayscale-img"
            src={sanityImgUtil(image, 768)}
            srcSet={sanityImgSrcSetUtil(image, 740, 340, 340)}
            dimensions={image.metadata?.dimensions}
            sizes="(maxWidth: 768px) 740px, (maxWidth: 1080px) 340px, 340px"
            crop={image.crop}
          />
        )}
        <p className="ColumnsAuthor__container__content font-400 graebenbach">
          {props.column.description}
        </p>
      </div>
    </div>
  );
});
