import React, { useEffect, useMemo } from 'react';
import { ArticleLink, Column } from 'sharedTypes';
import Language from 'constants/Language';
import { ColumnsAuthor } from './ColumnsAuthor';
import StoryTeaserSwitch from './StoryTeaserSwitch';
import { Button } from 'components/base';
import ApiClient from 'lib/ApiClient';
import Slider from 'react-slick';
import { curlyQuotes } from 'utils/text';
import articlesByDate from 'sanitizers/articlesByDate';

interface ColumnsProps {
  column: Column;
}

export const ColumnsCarousel: React.FC<ColumnsProps> = (props) => {
  const { column } = props;
  const [articles, setArticles] = React.useState<ArticleLink[]>([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const articles = await ApiClient.fetchArticlesByColumn(column.slug);

      const reverseChronArticles = articlesByDate(articles);

      //max limit of articles is 12
      const maxLimitArticles = reverseChronArticles.slice(0, 12);

      setArticles(maxLimitArticles);
    };
    fetchArticles();
  }, [column.slug]);

  if (!articles) {
    return null;
  }

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
        },
      },
    ],
  };

  return (
    <div className="ColumnsCarousel view content-with-nav inner-content-max-width mxauto">
      <div className={'ColumnsCarousel__header'}>
        <h1 className={'ColumnsCarousel__name graebenbach font-700'}>
          {curlyQuotes(column.title)}
        </h1>
        <Button
          ariaLabel={Language.t('Global.seeFullCollection')}
          to={'/columns/' + column.slug}
          wrap={true}
        >
          <p className={'ColumnsCarousel__more graebenbach font-400'}>
            {curlyQuotes(
              Language.t('Global.allColumnStories', {
                title: column.title,
              })
            )}{' '}
            &rarr;&#xFE0E;
          </p>
        </Button>
      </div>
      <div className={'ColumnsCarousel__content__container mxauto'}>
        <Slider {...settings}>
          <ColumnsAuthor column={column} />
          {articles &&
            articles.map((article) => (
              <div className={'ColumnsCarousel__teaser'} key={article.id}>
                <StoryTeaserSwitch
                  teaser={'teaser-image-text-sm'}
                  article={article}
                  hideSectionIcon={false}
                  isCarouselTeaser={true}
                />
              </div>
            ))}
        </Slider>
      </div>
      <div className={'ColumnsCarousel__underline'} />
    </div>
  );
};
