import React, { Component } from 'react';
import cx from 'classnames';
import AdSense from 'react-adsense';

import Language from 'constants/Language';
import { AdSizeStory, AdSizeLayout } from 'sharedTypes';

interface Props {
  size: AdSizeStory | AdSizeLayout;
}

class Ad extends Component<Props> {
  render() {
    const { size } = this.props;
    const [width, height] = size.split('x');

    return (
      <div
        className={cx(`Ad Ad--size-${size} my3`, {
          'none md:block': size === AdSizeStory['300x600'],
          'md:none': size === AdSizeStory['320x50'],
        })}
      >
        <div className="Ad__container flex flex-col items-center justify-center">
          <div className="Ad__container__wrapper">
            <div className="Ad__container__ad">
              <div className="Ad__container__target bg-color-gray-lighter relative">
                <AdSense.Google
                  client="282843435"
                  slot="301931795"
                  style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                  }}
                  format=""
                />
              </div>
            </div>
            <div className="Ad__container__text text-center uppercase text-section-details-xs graebenbach my_5 color-gray-light">
              {Language.t('Global.advertisement')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ad;
